<template>
  <v-container>
    <v-row>
      <v-col>
        <v-autocomplete
          label="Periodo"
          item-text="nombre"
          item-value="id"
          :items="periodos"
          v-model="periodoId"
          @change="obtAsignaturas"
        >
        </v-autocomplete>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-autocomplete
          :items="grupos"
          item-text="nombre"
          item-value="id"
          label="Grupo"
          v-model="grupoId"
          @change="obtAsignaturas"
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card class="mx-auto">
          <v-card-title class="">
            Malla docente
            
          </v-card-title>
          <v-card-text>
            <v-divider></v-divider>
            <v-container>
              <template v-for="(item, index) in asignaturas">
                <v-row :key="index" justify="center" align="baseline">
                  <v-col cols="12" md="4">
                    <v-chip class="ma-2" label outlined>
                      {{ item.nombreAsignatura }}
                    </v-chip>
                  </v-col>
                  <v-col cols="12" md="8">
                    <v-autocomplete
                      :items="docentes"
                      label="Docente"
                      item-text="nombre"
                      item-value="id"
                      v-model="item.docenteId"
                      @change="updateDocente(item)"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row :key="'i'+index">
                  <v-col>
                    <v-divider></v-divider>
                  </v-col>
                </v-row>
              </template>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  data: () => ({
    asignaturas: [],
    periodoId: 0,
    grupoId: 0,
    docentes: [],
    periodos: [],
    malla: [],
    dialog: false,
    grupos:[],
    editedItem: {
      cursoId: 0,
      asignaturaId: 0,
      docente: "",
      asignaturaId: "",
    },
  }),
  computed: {
    
  },
  created() {
    this.initialize();
  },
  methods: {
    ...mapActions(["GetDocentes", "GetGrupos", "GetPeriodos", "GetMallaGrupo","RegistrarMallaPeriodo","ActualizarMallaPeriodoDocente","ModificarMensaje"]),
    initialize() {
      this.GetGrupos().then((x) => (this.grupos = x.data));
      this.GetDocentes().then((x) => (this.docentes = x.data));
      this.GetPeriodos().then((x) => (this.periodos = x.data));
    },
    obtAsignaturas() {
      if (this.periodoId > 0 && this.grupoId > 0) {
        let parametros = {
          grupoId: this.grupoId,
          periodoId: this.periodoId,
        };

        this.GetMallaGrupo(parametros).then((x) => {
          let mallas = x.data.map((item) => {
            let malla = {};

            malla.cursoId = item.cursoId;
            malla.asignaturaId = item.asignaturaId;
            malla.nombreAsignatura = item.nombreAsignatura;
            malla.mallaId = item.mallaId;
            malla.docenteId = 0;
            malla.mallaPeriodoId = 0

            if (!!item.mallaPeriodo) {
              malla.docenteId = item.mallaPeriodo.docenteId;
              malla.mallaPeriodoId = item.mallaPeriodo.id
            }

            return malla;
          });

          this.asignaturas = mallas;
        });
      } else {
        this.asignaturas = [];
      }
    },
    updateDocente(item){

      let mallaPeriodo = {};
      mallaPeriodo.id = item.mallaPeriodoId;
      mallaPeriodo.DocenteId = item.docenteId;
      mallaPeriodo.MallaId = item.mallaId;
      mallaPeriodo.periodoId = this.periodoId;
      mallaPeriodo.grupoId = this.grupoId;
     
      if(mallaPeriodo.id > 0)
      {
          this.ActualizarMallaPeriodoDocente(mallaPeriodo).then(x=>{
          this.ModificarMensaje({texto:'Actualización Exitosa!'})
        });
      }
      else{
        let itemModificar = item;
        this.RegistrarMallaPeriodo(mallaPeriodo).then(x=>{
          itemModificar.mallaPeriodoId = x.data.id;
          this.ModificarMensaje({texto:'Registro Exitoso!'})
        });
      }

    }
  },
};
</script>