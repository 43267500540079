<template>
    <MallaDocentes></MallaDocentes>
</template>
<script>
import MallaDocentes from "@/components/MallaDocentes.vue"
export default {
   components:{
      MallaDocentes 
   } 
}
</script>